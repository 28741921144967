import RootLayout from "@/component/RootLayout";
import Footer from "@/component/footer/footer";
import Header from "@/component/header/header";
import Home from "@/component/home/home";
import Navbar from "@/component/navbar/navbar";

export default function HomePage() {
    return (
        <RootLayout>
            <Header />
            <Navbar />
            <Home />
            <Footer />
        </RootLayout>
    );
}