import Image from 'next/image';
import React from 'react'
import { AiFillSafetyCertificate } from "react-icons/ai";
import { Ri24HoursFill } from "react-icons/ri";
import { RiTeamFill } from "react-icons/ri";
import imagehome from "../../../public/video_image.jpg"
import { motion } from 'framer-motion';


const Home = () => {
  const combinedVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, ease: "easeInOut" }
    },
    hover: {
      scale: 1.2,
      transition: { duration: 0.3, ease: "easeInOut" }
    }
  };

  return (
    <div className="container mx-auto px-4 xl:px-0 py-4">
      <div className="w-full py-11 flex justify-center">
        <h2 className="text-2xl text-center font-bold">
          “We Provide Motivated Workforce To Build Your Dreams and Projects”
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="text-center">
          <div className="mb-4 flex justify-center">
            <motion.span
              className="text-6xl text-[#0060b1] cursor-pointer"
              variants={combinedVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
            >
              <AiFillSafetyCertificate />
            </motion.span>
          </div>
          <h2 className="text-xl font-semibold">Safety</h2>
        </div>
        <div className="text-center">
          <div className="mb-4 flex justify-center">
            <motion.span
              className="text-6xl text-[#0060b1] cursor-pointer"
              variants={combinedVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
            >
              <Ri24HoursFill />
            </motion.span>
          </div>
          <h2 className="text-xl font-semibold">24*7 Available</h2>
        </div>
        <div className="text-center">
          <div className="mb-4 flex justify-center">
            <motion.span
              className="text-6xl text-[#0060b1] cursor-pointer"
              variants={combinedVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
            >
              <RiTeamFill />
            </motion.span>
          </div>
          <h2 className="text-xl font-semibold">500 + Our Team</h2>
        </div>
      </div>
      <div className="py-16 md:py-24">
        <div className='container mx-auto py-4'>
          <h2 className="text-xs md:text-lg uppercase font-semibold text-[#0060b1]">
            ABOUT US
          </h2>
        </div>
        <div className="container mx-auto flex flex-wrap lg:flex-nowrap">
          <div className="w-full lg:w-1/2 py-4 pr-0 lg:pr-2">
            <p className="mb-6 md:mb-8 text-sm md:text-base lg:text-lg">
              Workforce7 Inc. provides professional flagging services with safety as utmost priority. We are committed to serving companies throughout New York City and Westchester County. We focus on your comprehensive business requirements and provide end to end service to optimize your project. Workforced7.Inc is accredited by the American Traffic Safety Services Association certified.
            </p>
            <p className="mb-6 md:mb-8 text-sm md:text-base lg:text-lg">
              We have been leading Flagger providers since 2015 for major organizations for their infrastructure, utilities and other service industries.We focus highly on rigorous training as per the national and state standard requirements. Our expert team manages your traffic and projects seamlessly
            </p>
            <p className="mb-6 md:mb-8 text-sm md:text-base lg:text-lg">
              Our personnel are dedicated and provide high-quality traffic control services to eliminate any danger associated with the construction site. Our Flagger specialists follow health and safety measures and provide additional guidance in emergency situations.
            </p>
            {/* <div className="mt-6 md:mt-8">
              <button className="text-white bg-[#0060b1] hover:bg-[#0060b1] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">
                Read More
              </button>
            </div> */}
          </div>
          <div className="w-full lg:w-1/2 py-4 pl-0 lg:pl-2 animate-slideInLeft">
            <motion.div
              className="w-full h-auto rounded shadow-md"
              variants={combinedVariants}
              initial="hidden"
              animate="visible"
            >
              <Image
                src={imagehome}
                alt="Image"
                className="w-full h-auto rounded shadow-md"
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
